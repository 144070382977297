.horizontal {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.vertical {
    display: flex;
    flex-direction: column;
}
