@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.asideGroup {
    font-family: $code-font-stack;
    color: $dark-subtitle-colour;
    text-align: right;
    position: fixed;
    display: flex;

    // Reset default <nav> and <li> styling.
    ul {
        @include reset-ul-styles;
    }

    li a {
        text-decoration: none;
        color: inherit;
    }
}

@media screen and (min-width: $lg) {
    .asideGroup {
        right: $spacing-md;
        top: $central-container-top-offset;
        transform: translateY(-50%);
        flex-direction: column;

        li:not(:last-child) {
            padding-bottom: 8px;
            width: 70px;
            border-bottom: 1px dotted $dark-subtitle-colour;
        }

        li:not(:first-child) {
            padding-top: 8px;
        }
    }
}

@media screen and (max-width: $lg) {
    .asideGroup {
        // Anchor the aside group to the top of the viewport.
        left: 50%;
        top: 1.5rem;
        transform: translateX(-50%);
        width: 80%;

        ul {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            li {
                border-bottom: 1px dotted $dark-subtitle-colour;
            }
        }
    }
}
