@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.quote {
    font-family: $regular-font-stack;
    border-left: 5px solid $cool-grey-400;
    background: hsla(0, 0%, 0%, 0.25);
    padding: $spacing-xs $spacing-sm $spacing-xs 0;
    margin: 0px $spacing-sm;
    width: 100%;
    max-width: $sm;
    border-radius: 0 10px 10px 0;

    // Bottom center the quote.
    position: fixed;
    bottom: $spacing-sm;
    left: 50%;
    transform: translateX(-50%);

    blockquote {
        // font-style: italic;
        color: $dark-text-colour;
        margin: 0px $spacing-sm $spacing-xs $spacing-sm;
    }

    p {
        margin: 0;
        color: $cool-grey-800;
    }

    figcaption {
        margin: 0px $spacing-sm 0px $spacing-sm;
        color: $cool-grey;
    }

    .randomiseButton {
        @include reset-button-styles;
        position: absolute;
        right: $spacing-xs;
        bottom: $spacing-xs;
        cursor: pointer;

        transition: all 100ms ease-in-out;

        .icon {
            font-size: $font-size-xl;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}

// Hide the quote when the screen is too narrow OR the screen is too short.
@media screen and (max-width: $lg), screen and (max-height: $md) {
    .quote {
        display: none;
    }
}
