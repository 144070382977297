@import "../styles/variables.scss";

.greeting {
    font-family: $code-font-stack;
    color: $dark-subtitle-colour;
}

.name {
    color: $secondary;
}

.headline {
    color: $dark-subtitle-colour;
    font-family: $code-font-stack;
    font-size: larger;
    height: 2em;
}

.description {
    color: $dark-subtitle-colour;
    line-height: $line-spacing-md;
}

.buttonStack {
    margin-top: 2rem;
}
